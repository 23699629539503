import Vue from "vue";
import createRouter from "./router";
import App from "./App.vue";

function mountApp() {
  const router = createRouter();

  Vue.config.productionTip = false;
  new Vue({
    router,
    render: (h) => h(App),
  }).$mount("#app");
}

var script = document.createElement("script");
script.src = "/v1/env.js";
script.onload = () => {
  mountApp();
};
script.onerror = () => {
  mountApp();
};
document.head.appendChild(script);

let subscriptionDetail = null;
import { getCompany, setCompany } from "@/helper/utils";
import { isNode } from "browser-or-node";

export const checkSubscription = (
    to,
    from,
    next) => {
    if (isNode) {
        return next();
    }
    const { params } = to;
    if (params.company_id) {
        setCompany(params.company_id);
    }
    import('../services/main.service').then(service => {
        if (subscriptionDetail && subscriptionDetail.subscription && subscriptionDetail.subscription.status === 'active') {
            next();
            return;
        }
        service.default.getSubscriptionPlan()
            .then(({ data }) => {
                subscriptionDetail = data;
                if (subscriptionDetail && subscriptionDetail.subscription && subscriptionDetail.subscription.status === 'active') {
                    next();
                }
                else {
                    next(`/company/${getCompany()}/pricing`);
                }
            })
            .catch(err => {
                console.log(err);
                next(`/company/${getCompany()}/pricing`);
            })
    });
}



export const checkAdmin = (to, from, next) => {
    const notFoundPage = "/page-not-found";
    const { params } = to;
    const features = window?.env?.features || {}
    if (!features["FRTI_4165"]) {
      return next(notFoundPage);
    }
    if (params.company_id) {
      setCompany(params.company_id);
    }
    import("../services/main.service").then((service) => {
      service.default
        .isAdmin()
        .then(({ data }) => {
          if (data.isAdmin) {
            next();
          } else {
            next(notFoundPage);
          }
        })
        .catch(() => {
          next(notFoundPage);
        });
    });
  };